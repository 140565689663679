@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/AvenirNextCyr-Demi.woff2") format('woff2');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/AvenirNextCyr-Medium.woff2") format('woff2');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/AvenirNextCyr-Regular.woff2") format('woff2');
}